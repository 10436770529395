import React, {Component} from 'react';
import ShopifyState from 'src/store/ShopifyState';
import { Subscribe } from 'unstated';
import Close from 'src/components/ui/Close';
import Navlink from 'src/components/ui/NavLink';

import  './cart.module.scss';

const sizesMap = {
  'SMALL': 'S',
  'MEDIUM': 'M',
  'LARGE': 'L',
  'X-LARGE': 'XL',
  'XX-LARGE': 'XXL',
}

const displaySize = (size) => {
  return size in sizesMap ? sizesMap[size] : size;
}


class Cart extends Component {
  constructor(props) {
    super(props);
    this.onCheckoutClick = this.onCheckoutClick.bind(this);
  }
  onCheckoutClick() {
    Navlink.externalNavigate().then(() => {
      window.location = ShopifyState.state.checkout.webUrl;
    })
  }
  render() {
    return (
      <Subscribe to={[ShopifyState]}>{({state}) => {
        console.log(state, ShopifyState);
        if (!state.initialized) {
          return <div>loading</div>
        }
        return (
          <div styleName="wrap">
            <div styleName="background"></div>
            <div styleName="items-wrap">
              {(state.checkout.lineItems || []).map((item) => (
                <div styleName="item-wrap" key={item.id}>
                  <div>
                    <div styleName="item-image-wrap">
                      <div styleName="item-image" style={{backgroundImage: `url(${item.variant.image.src})`}}></div>

                    </div>
                    <br className="mobile-only"/>
                    <div styleName="item-title" className="mobile-only">{item.title} ({displaySize(item.variant.title)})</div>

                  </div>
                  <div styleName="item-title" className="desktop-only">{item.title} ({displaySize(item.variant.title)})</div>

                  <div styleName="item-price">{item.quantity} x ${item.variant.price.split('.')[0]}</div>
                  <div styleName="item-remove">
                    <Close onClick={() => {ShopifyState.removeVariant(item.id)}} />
                  </div>
                </div>
              ))}
              <div styleName="cart-total">
                ${state.checkout.lineItemsSubtotalPrice.amount.split('.')[0]}
              </div>
              <button onClick={this.onCheckoutClick} styleName="cta">Checkout</button>
            </div>
          </div>
        )}}</Subscribe>
      );
    }
  }


  export default Cart;
