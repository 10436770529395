import React, {Component} from 'react';
import { Subscribe } from 'unstated';

import ConcertsState from 'src/store/ConcertsState';

import './live.module.scss';
import TextWithLine from 'src/components/ui/TextWithLine';


class Live extends Component {
  render() {
    return (
      <Subscribe to={[ConcertsState]}>{({state}) => (
        state.loading ? (
          <div>loading</div>
        ): (
          <div styleName="wrap">
            <div styleName="background"></div>
            <div styleName="shows-wrap">
              {state.concerts.map((country) => (
                <div key={country.country}>
                  <div styleName="concert-country">
                    {country.country}
                  </div>
                  {country.shows.map((show, i) => (
                    <a
                      styleName="show-button"
                      key={show.date}
                      href={show.link}
                      style={{
                        animationDelay: `${100 + (i * 30)}ms`
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div styleName="show" >
                        <div styleName="show-date">
                          <TextWithLine>{show.date}</TextWithLine>
                        </div>
                        <div className="desktop-only">
                          <div styleName="show-city">
                            <TextWithLine inCenter>{show.city}</TextWithLine>
                          </div>
                        </div>
                        <div className="mobile-only" style={{width: '100%'}}>
                          <div styleName="show-city">
                            <TextWithLine reverse>{show.city}</TextWithLine>
                          </div>
                        </div>
                        <div styleName="show-name">
                          <TextWithLine reverse>{show.name}</TextWithLine>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
              ))}
              {!state.concerts.length && (
                <div className="center">
                  No Upcoming Shows
                </div>
              )}
            </div>
          </div>
        )
      )}</Subscribe>
    );
  }
}


export default Live;
