import { Container } from 'unstated';
import * as THREE from 'three';

const loadTexture = (url, side) =>  {
  return new Promise((resolve, reject) => (
    new THREE.TextureLoader().load(url, (texture) => {resolve({side, texture})})
  ));
}

class TexturePreloader extends Container {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      textures: {},
    }
  }
  load() {
    const images = [

    ];
    const promises = images.map(image => loadTexture(image[0], image[1]));
    return Promise.all(promises).then((result) => {
      const textures = {};
      result.forEach((img) => {
        textures[img.side] = img.texture;
      });
      this.setState({
        textures,
        loaded: true,
      });
      return textures;
    })
  }
}

const t = new TexturePreloader();

export default t;
