import React from 'react';
// import styled from 'styled-components';
// import Page from 'src/components/layout/Page';
// import ReactSVG from 'react-svg';
import ShopifyState from 'src/store/ShopifyState';
import { Subscribe } from 'unstated';
import Product from 'src/components/misc/Product';
import './shop.module.scss';
import ItemAdded from 'src/components/misc/ItemAdded';




const Shop = ({ history, pageTransitionState }) => (
  <div styleName="wrap">
    <div styleName="background-shop"></div>
    <Subscribe to={[ShopifyState]}>{({state}) => {
      const {initialized, products} = state;
      if (!initialized) {
        return (
          <div>loading</div>
        )
      }
      console.log(products);
      return (
        <div styleName="content-section">
          {products.map((product) => (
            <Product product={product} key={product.id}/>
          ))}
        </div>
      )}
    }</Subscribe>
    <ItemAdded />
  </div>
);


export default Shop;
