import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import NavLink from 'src/components/ui/NavLink';
import ReactSVG from 'react-svg';

import amharic from 'src/assets/img/amharic.svg';
import cart from 'src/assets/img/cart.svg';

import styles from './Logo.module.scss';

const CENTERED = 'CENTERED';
const BOTTOM = 'BOTTOM';
const STORE = 'STORE';

class Logo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: this.getModeWithPath(this.props.location.pathname),
    }

    this.onLogoClick = this.onLogoClick.bind(this);
    this.onCartClick = this.onCartClick.bind(this);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
    if (this.props.leaving !== prevProps.leaving && this.props.leaving) {
      this.setModeWithPath(this.props.destination);
    }
  }
  setModeWithPath(path) {
    this.setState({
      mode: this.getModeWithPath(path),
    });
  }
  getModeWithPath(path) {
    if (path === '/') {
      return CENTERED;
    } else if (path === '/shop' || path === '/music') {
      return STORE;
    } else {
      return BOTTOM;
    }
  }
  onLogoClick() {
    NavLink.navigate(this.props.history, '/');
  }
  onCartClick() {
    NavLink.navigate(this.props.history, '/cart');
  }
  render() {
    const { mode } = this.state;
    let logoClass = (
      mode === CENTERED ? 'centered' : (
      mode === BOTTOM ? 'bottom' : (
      mode === STORE ? 'store' :

      /* else */ 'hidden'
    )))
    return  (
      <div styleName={`logo-wrap ${logoClass}`}>
        <button onClick={this.onLogoClick} styleName="logo-name-wrap">
          <div styleName="logo-inner" >
            <div styleName="logo-name" >
              KELELA
            </div>
            <div styleName="logo-line"></div>
            <div styleName="logo-symbol">
              <ReactSVG src={amharic} svgClassName={styles['svg-icon']}/>
            </div>
          </div>
        </button>
        <button styleName="cart" onClick={this.onCartClick}>
          <ReactSVG src={cart} svgClassName={styles['cart-svg-icon']}/>
        </button>
      </div>
    )
  }
}

export default withRouter(Logo);
