import React, { Component } from 'react';
import TimelineMax from "gsap/TimelineMax";

import TweenMax from "gsap/TweenMax";
import {Expo} from "gsap/EasePack";

import './LoadingBar.module.scss'
console.log(Expo);

class LoadingBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    }
  }
  componentDidMount() {
    const { leaving } = this.props;

    this.tl = new TimelineMax({
      // paused: true,
      onComplete: () => {
        console.log('complete');
      }
    });
    if (leaving) {
      this.beginLeave();

    }

    this.leaveTl = new TimelineMax({ paused: true });
  }
  componentDidUpdate(prevProps) {
    const { leaving } = this.props;
    if (prevProps.leaving !== leaving) {
      if (leaving) {
        console.log('leaving');
        this.beginLeave();
      } else {
        console.log('done');

        this.completeLeave();
      }
    }
  }
  beginLeave() {
    TweenMax.fromTo(this.bar, this.props.duration, {
      width: '0%',
      y: 0,
      autoAlpha: 1,
      ease: Expo.easeInOut,

    }, {
      width:'100%',
    });
  }
  completeLeave() {
    TweenMax.fromTo(this.bar, 0.4, {
      y: 0,
      autoAlpha: 1,
    }, {
      y: 8,
      delay: 0.25,
      autoAlpha: 0,
    });
  }
  render() {
    return (
      <div styleName="wrap">
        <div styleName="inner" id="loading-bar" ref={(el) => {this.bar = el}}/>
      </div>
    )
  }
}

LoadingBar.defaultProps = {
  leaving: false,
  duration: 0.6,
}

export default LoadingBar;
