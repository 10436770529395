import ololog from 'ololog';
import bullet from 'string.bullet';
import { dim } from 'ansicolor';

const noop = () => {};
let log = noop;

if (process.env.NODE_ENV === "development") {
  log = (...args) => {console.log('(development):', ...args)}
}


export const createLog = (tag) => {
  if (process.env.NODE_ENV === "development") {
    return ololog.configure({
      time: {
        yes: true,
      },
      tag: (lines) => {
        if (tag) {
          return bullet(dim(`[${tag}] (development)`) + ': ', lines);
        }
        return bullet(dim(`(development):`), lines);
      }
    });
  }
  return () => {
    return noop;
  }
}
export default log;
