import React from 'react';

import './AlbumsView.module.scss';

const AlbumsView = ({albums, playlists}) => {console.log(1, albums); return(
  <div styleName="content-section">
    <div styleName="section-title">Albums</div>
    <div styleName="albums-wrap">
      {Object.keys(albums).map((key, i) => (
        <a key={key} styleName="album-wrap" href="#a">
          <div styleName="album-inner" style={{animationDelay: `${i*90}ms`}}>
            <div styleName="album-bg" style={{backgroundImage: `url(${albums[key].image})`}} />
            <div styleName="album-fg"></div>
            <div styleName="album-title">{albums[key].title}</div>
          </div>
        </a>
      ))}
    </div>
    <div styleName="section-title">Playlists</div>
    <div styleName="albums-wrap" className="1">
      {playlists.map((playlist, i) => (
        <a key={playlist.title} styleName="album-wrap" href={playlist.link} target="_blank">
          <div styleName="album-inner" style={{animationDelay: `${i*90}ms`}}>
            <div styleName="album-bg" style={{backgroundImage: `url(${playlist.image})`}} />
            <div styleName="album-fg"></div>
            <div styleName="album-title">{playlist.title}</div>
          </div>
        </a>
      ))}
    </div>
  </div>
);
}


export default AlbumsView;
