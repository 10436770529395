import { Container } from 'unstated';
import api from 'src/tools/api';
import moment from 'moment';
import globals from 'src/globals';
import devlog from 'src/tools/devlog';

const noop = () => {};

const parseConcertData = (data) => {
  const concerts = []
  if(
    data.resultsPage &&
    data.resultsPage.results &&
    data.resultsPage.results.event &&
    data.resultsPage.results.event.length
  ) {
    const allShows = data.resultsPage.results.event.map((show) => ({
      date: moment(show.start.date, 'YYYY-MM-DD').format('M.D.YYYY'),
      name: show.venue.displayName,
      city: show.location.city,
      state: show.venue.metroArea.state ? show.venue.metroArea.state.displayName : '',
      country: show.venue.metroArea.country.displayName,
      link: show.uri,
    }));

    allShows.forEach((show) => {
      const countryObject = concerts.find(c => c.country === show.country);
      if (countryObject) {
        countryObject.shows.push(show);
      } else {
        concerts.push({
          country: show.country,
          shows: [
            show
          ],
        });
      }
    });
  }
  return concerts;
}

class ConcertsState extends Container {
  state = {
    loading: true,
    concerts: [],
  };

  fetch(cb = noop) {
    this.setState({
      loading: true,
    });
    api({
      url: `${globals.songKickUrl}${globals.songKickApiKey}`,
    }).then(({data}) => {

      const concerts = parseConcertData(data);
      devlog('concerts', concerts);

      this.setState({
        concerts,
        loading: false,
      });
    });
  }
}

const s = new ConcertsState();

export default s;//PageTransitionState;
