import React, { Component, Fragment } from 'react';

import { Provider, Subscribe } from 'unstated';
import Routes from 'src/Routes';

import PageTransitionState from 'src/store/PageTransitionState';
import ShopifyState from 'src/store/ShopifyState';
import textureLoader from 'src/tools/textureLoader';
import ConcertsState from 'src/store/ConcertsState';
import LoadingBar from 'src/components/ui/LoadingBar';

import globals from 'src/globals';

import loadImage from 'src/tools/loadImage';
import api from 'src/tools/api';

import 'intersection-observer';
import '@glidejs/glide/dist/css/glide.core.min.css';
import './scss/main.scss';

const preloadImages = [
  require('src/assets/img/home.jpg'),
  require('src/assets/img/detroit.jpg'),
  require('src/assets/img/videos.jpg'),
  require('src/assets/img/album/takemeapart.jpg'),
  require('src/assets/img/album/takemeapart-remix.jpg'),
  require('src/assets/img/album/cut4me.jpg'),
  require('src/assets/img/album/cut4me-remix.jpg'),
  require('src/assets/img/album/hallucinogen.jpg'),
  require('src/assets/img/album/hallucinogen-remix.jpg'),

]


function getYoutubeVideoID(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
}


const Splash = () => (
  <div>
    <LoadingBar leaving duration={0.8} />
  </div>
);

const loadVideos = () => {
  return new Promise((resolve) => {
    api({
      url: `${globals.WP_API_URL}/index.php/wp-json/wp/v2/video`,
    }).then(({ data }) => {
      const promises = [];
      data.forEach((video) => {
        const youtubeLink = video.acf.videos.match(/src="([^\s?]*)/g)[0];
        const videoId = getYoutubeVideoID(youtubeLink);
        promises.push(loadImage(`https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`))
      });
      Promise.all(promises).then(resolve);
    });

  })
}

const loadShopifyProducts = (client) => {
  return new Promise((resolve) => {
    ShopifyState.initialize(client).then(({ products }) => {
      const promises = [];
      products.forEach((product) => promises.push(loadImage(product.images[0].src)));
      Promise.all(promises).then(resolve);
    });
  })
}

class App extends Component {
  componentWillMount() {
    const fakeLoad = () => {
      return new Promise((resolve) => {
        setTimeout(resolve, 800);
      });
    }
    const promises = [
      ConcertsState.fetch(),
      loadVideos(),
      fakeLoad()
      // loadShopifyProducts(this.props.client),
    ];
    preloadImages.forEach((image) => {promises.push(loadImage(image))});
    Promise.all(promises).then(() => {
      PageTransitionState.completeLoad();
    })
  }
  render() {
    return (
      <Provider>
        <Subscribe to={[PageTransitionState]}>{(pageTransitionState) => (
          pageTransitionState.state.loading ? (
            <Splash />
          ):(
            <Routes client={this.props.client} pageTransitionState={pageTransitionState}/>
          )
        )}</Subscribe>
      <Fragment>
          <div className="axis">
            <div className="vaxis"></div>
            <div className="tmargin"></div>
            <div className="bmargin"></div>
            <div className="haxis"></div>
            <div className="rmargin"></div>
            <div className="lmargin"></div>
          </div>
          <div className="borders_">
            <div className="border_t"></div>
            <div className="border_l"></div>
            <div className="border_r"></div>
            <div className="border_b"></div>
          </div>
        </Fragment>
      </Provider>
    );
  }
}



export default App;
