import React from 'react';

import './TextWithLine.module.scss';

const TextWithLine = ({children, reverse = false, inCenter = false}) => (
  <div styleName={`wrap ${reverse ? 'reverse' : ''} ${inCenter ? 'in-center' : ''}`} >
    <div styleName="text">{children}</div><div styleName="line" className="__text-with-line"/>
  </div>
)

export default TextWithLine;
