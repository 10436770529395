import React from 'react';
// import styled from 'styled-components';
// import Page from 'src/components/layout/Page';
// import ReactSVG from 'react-svg';
import ApiWrap from 'src/components/misc/ApiWrap';

import './videos.module.scss';
// import CircleTape from 'src/components/misc/CircleTape';

/*
https://www.youtube.com/oembed?url=http://www.youtube.com/watch?v=py6PgXq0yDM&format=json

*/

function getYoutubeVideoID(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
}

const Videos = ({ history, pageTransitionState }) => (
  <div styleName="wrap">
    <div styleName="background"></div>
    <div styleName="content">
      <ApiWrap path="video">{(videos) => {
        return videos.map((video) => {
          const youtubeLink = video.acf.videos.match(/src="([^\s?]*)/g)[0];
          const videoId = getYoutubeVideoID(youtubeLink);
          return (
          <div styleName="video-wrap" key={video.id}>
            <div
              styleName="iframe-wrap"
              dangerouslySetInnerHTML={{__html: video.acf.videos}}
              style={{backgroundImage: `url(https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg)`}}
              ></div>
            <div styleName="details">
              <span className="bold ">{video.title.rendered}: </span>
              <span styleName="subtext">{video.acf.description}</span>
            </div>
          </div>
        )});
      }}</ApiWrap>
    </div>
  </div>
);


export default Videos;
