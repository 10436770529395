import React, { Component } from 'react';
import { withRouter } from 'react-router';
// import { HashLink as Link } from 'react-router-hash-link';
import PageTransitionState from 'src/store/PageTransitionState';

const PAGE_EXIT_TIME = 700;

class NavLink extends Component {
  static navigate(history, to) {
    const sections = document.querySelectorAll('.__section.seen');
    Array.from(sections).forEach((section) => {
      section.classList.remove('seen');
    });
    PageTransitionState.leave(to);
    setTimeout(() => {
      history.push(to);
      PageTransitionState.completeLeave();
    }, PAGE_EXIT_TIME);
  }
  static externalNavigate() {
    return new Promise((resolve) => {
      const sections = document.querySelectorAll('.__section.seen');
      Array.from(sections).forEach((section) => {
        section.classList.remove('seen');
      });
      PageTransitionState.leave();
      setTimeout(() => {
        // PageTransitionState.completeLeave();
        resolve();
      }, PAGE_EXIT_TIME);
    })
  }
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(e) {
    if (this.props.external) {
      window.open(this.props.to, '_blank');
      return;
    }
    if (this.props.location.pathname === this.props.to) {
      return;
    }
    NavLink.navigate(this.props.history, this.props.to);
  }
  render() {
    return (
      <button onClick={this.handleClick} className={this.props.className}>
        {this.props.children}
      </button>
    )
  }
}

export default withRouter(NavLink);
