import React, { Component } from 'react';

import AlertBannerView from './AlertBannerView';

class AlertBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showing: false,
      leaving: false,
    }
  }
  render() {
    const {showing} = this.state;
    return (
      showing &&
      <AlertBannerView/>
    );
  }
}

export default AlertBanner;
