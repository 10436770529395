import React, { Component } from 'react';
import { withRouter } from 'react-router';
import ReactSVG from 'react-svg';
import Close from 'src/components/ui/Close';
import emitter from 'src/tools/emitter';
import NavLink from 'src/components/ui/NavLink';

import './ItemAdded.module.scss';

class ItemAdded extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      itemAdded: {},
    }
    this.close = this.close.bind(this);
    this.goToCheckout = this.goToCheckout.bind(this);
    this.onItemAdd = this.onItemAdd.bind(this);
  }
  componentDidMount() {
    emitter.on('item-added', this.onItemAdd);
  }
  componentWillUnmount() {
    clearTimeout(this.timeout);
    emitter.removeListener('item-added', this.onItemAdd);
  }
  onItemAdd(itemAdded) {
    console.log(' 3333 added: ', itemAdded);
    this.setState({
      open: true,
      itemAdded,
    });
    this.timeout = setTimeout(() => {
      this.setState({
        open: false,
        
      });
    }, 5000);
  }
  goToCheckout() {
    NavLink.navigate(this.props.history, '/cart');
  }
  close() {
    this.setState({
      open: false,
    })
  }
  render() {
    const {open, itemAdded} = this.state;
    const image = (itemAdded.images && itemAdded.images.length) ? itemAdded.images[0].src : '';
    return (
      <div styleName={`wrap ${open ? 'open': ''}`}>
        <div styleName="close">
          <Close onClick={this.close}/>
        </div>
        <div styleName="center">
          <div styleName="title">
            Item Added To Cart
          </div>
          <button styleName="product-button" onClick={this.goToCheckout}>
            Checkout
          </button>
        </div>
        <div styleName="right">
          <div styleName="item-name">
            {itemAdded ? itemAdded.title : ''}
          </div>
          <div styleName="item-image" style={{backgroundImage: `url(${image})`}}></div>
        </div>
      </div>
    )
  }
}

export default withRouter(ItemAdded);
