import React, { Component } from 'react';
import devlog from 'src/tools/devlog.js';

import api from 'src/tools/api.js';
import Loading from 'src/components/ui/Loading';

import globals from 'src/globals';

class ApiWrap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    }
  }
  componentWillMount() {
    const url = this.props.path ? (
      `${globals.WP_API_URL}/index.php/wp-json/wp/v2/${this.props.path}`
    ) : this.props.url;

    api({
      url,
      method: this.props.method || 'GET',
    }).then((response) => {
      devlog(url, response.data);
      this.setState({
        loaded: true,
        data: response.data,
      });
    })
  }
  render() {
    const { loaded, data } = this.state;
    return (
      loaded ? (
        this.props.children(data)
      ) : (
        <div>
          <Loading />
        </div>
      )
    )
  }
}

export default ApiWrap;
