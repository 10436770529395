import React from 'react';
// import { Transition } from 'react-spring/renderprops'
// import { Subscribe } from 'unstated';

import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from 'src/components/pages/home';
import Music from 'src/components/pages/music';

import Live from 'src/components/pages/live';
import Videos from 'src/components/pages/videos';
import Shop from 'src/components/pages/shop';
import Cart from 'src/components/pages/cart';

import Nav from 'src/components/ui/Nav';
import Logo from 'src/components/ui/Logo';

import LoadingBar from 'src/components/ui/LoadingBar';
import AlertBanner from 'src/components/ui/AlertBanner';

import BottomCurtain from 'src/components/ui/BottomCurtain';

// import NoMatch from 'src/components/pages/no-match';


// fedex caseid 47520399
const Routes = ({client, pageTransitionState}) => (
  <BrowserRouter>
    <Nav/>
    <Switch >
      <Route exact path="/" render={(props) => (<Home {...props} pageTransitionState={pageTransitionState} />)} />
      <Route exact path="/live" render={(props) => (<Live {...props} pageTransitionState={pageTransitionState} />)} />
      <Route exact path="/music" render={(props) => (<Music {...props} pageTransitionState={pageTransitionState} />)} />
      <Route exact path="/videos" render={(props) => (<Videos {...props} pageTransitionState={pageTransitionState} />)} />
      <Route exact path="/shop" render={(props) => (<Shop {...props} pageTransitionState={pageTransitionState} />)} />
      <Route exact path="/cart" render={(props) => (<Cart {...props} pageTransitionState={pageTransitionState} />)} />

      <Route render={() => <div>404</div>} />
    </Switch>
    <Logo leaving={pageTransitionState.state.leaving} destination={pageTransitionState.state.destination}/>
    <LoadingBar leaving={pageTransitionState.state.leaving}/>
    <AlertBanner />
    <BottomCurtain leaving={pageTransitionState.state.leaving} destination={pageTransitionState.state.destination}/>
  </BrowserRouter>
);


export default Routes
