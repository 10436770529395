import React from 'react';

import './AlertBannerView.module.scss';

const AlertBannerView = () => (
  <div styleName="wrap">
    hi
  </div>
)

export default AlertBannerView;
