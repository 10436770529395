import React, { Component } from 'react';

// import TimelineMax from 'gsap/TimelineMax';
// import TweenMax from 'gsap/TweenMax';
// import {Back, Elastic} from 'gsap/EasePack';

import './Loading.module.scss';

class Loading extends Component {

  render() {
    return (
      <div styleName="loader">
        loading
      </div>
    )
  }
}

export default Loading;
