import { Container } from 'unstated';
import localForage from 'localforage';
import { createLog } from 'src/tools/log';
import devlog from 'src/tools/devlog';

const log = createLog('Shopify');

const createCheckout = (client) => {
  return new Promise((resolve, reject) => {
    return client.checkout.create().then((res) => {
      localForage.setItem('checkoutId', res.id).then(() => {
        resolve(res);
      });
    });
  });
}

const getCheckout = (client) => {
  return new Promise((resolve, reject) => {
    localForage.getItem('checkoutId').then((storedCheckoutId) => {
      if (storedCheckoutId) {
        return client.checkout.fetch(storedCheckoutId).then((storedCheckout) => {
          if (storedCheckout && storedCheckout.completedAt) {
            createCheckout(client).then((createdCheckout) => {
              resolve(createdCheckout)
            });
          } else {
            resolve(storedCheckout);
          }
        });
      } else {
        createCheckout(client).then(resolve);
      }
    });
  });
}


class ShopifyState extends Container {
  state = {
    initialized: false,
    loading: true,
    checkout: {
      webUrl: '',
      totalPrice: '24',
    }
  };
  constructor(props) {
    super(props);
    this.addVariantToCart = this.addVariantToCart.bind(this);
  }
  initialize(client) {
    log('Shopify Initializing...');
    return new Promise((resolve, reject) => {
      this.client = client;
      getCheckout(client).then((res) => {
        this.setState({
          id: res.id,
          checkout: res,
        });

        if (this.state.products && this.state.products.length && !this.state.initialized) {
          this.setState({
            initialized: true,
            loading: false,
          });
          resolve({
            products: this.state.products,
            checkout: res,
          });
          log('Shopify Initialized.');
        }

      });

      client.product.fetchAll().then((res) => {
        devlog('products', res);
        this.setState({
          products: res.map((p) => {
            return p;
          }),
        });
        if (this.state.checkout.webUrl && !this.state.initialized) {
          this.setState({
            initialized: true,
            loading: false,
          });
          log('Shopify Initialized.');
          resolve({
            products: res,
            checkout: this.state.checkout,
          });
        }
      });
    })
  }
  getCheckoutUrl() {
    if (!this.state.initialized || !this.state.checkout) {
      return '';
    }
    return this.state.checkout.webUrl;
  }
  getLineItems() {
    if (!this.initialized) {
      return [];
    }
    if (!this.state.checkout) {
      return [];
    }

    return this.state.checkout.lineItems;
  }
  count() {
    const lineItems = this.getLineItems();
    return (lineItems && lineItems.length) ? lineItems[0].quantity : 0;
  }
  addVariantToCart(variantId, quantity = 0) {
    if (!this.state.initialized) {
      return Promise.reject({ error: 'not done initializing' });
    }
    this.setState({
      loading: true,
    });
    const lineItemsToAdd = [{variantId, quantity: parseInt(quantity, 10)}]
    const checkoutId = this.state.id;
    return this.client.checkout.addLineItems(checkoutId, lineItemsToAdd).then((res, error) => {
      this.setState({
        checkout: res,
        loading: false,
      });
      return res;
    });
  }
  removeVariant(variantId, quantity = 0) {
    if (!this.state.initialized) {
      return Promise.reject({ error: 'not done initializing' });
    }
    this.setState({
      loading: true,
    });
    const checkoutId = this.state.id;
    return this.client.checkout.removeLineItems(checkoutId, [variantId]).then((res, error) => {
      this.setState({
        checkout: res,
        loading: false,
      });
      return res;
    });
  }
}

const s = new ShopifyState();

export default s;
