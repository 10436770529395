import React from 'react';
// import styled from 'styled-components';
// import Page from 'src/components/layout/Page';
// import ReactSVG from 'react-svg';

import './music.module.scss';
// import CircleTape from 'src/components/misc/CircleTape';

import takemeapart from 'src/assets/img/album/takemeapart.jpg';
import takemeapartRemix from 'src/assets/img/album/takemeapart-remix.jpg';
import cut4me from 'src/assets/img/album/cut4me.jpg';
import cut4meRemix from 'src/assets/img/album/cut4me-remix.jpg';
import hallucinogen from 'src/assets/img/album/hallucinogen.jpg';
import hallucinogenRemix from 'src/assets/img/album/hallucinogen-remix.jpg';
import aquaforia from 'src/assets/img/album/aquaforia.jpg';

import Albums from 'src/components/misc/Albums';

const albums = [{
  title: 'Take Me Apart',
  tracklist: 'tracklist',
  image: takemeapart,
}, {
  title: 'Take Me Apart - Remix',
  tracklist: 'tracklist',
  image: takemeapartRemix,
}, {
  title: 'Cut 4 Me',
  tracklist: 'tracklist',
  image: cut4me,
}, {
  title: 'Cut 4 Me - Remix',
  tracklist: 'tracklist',
  image: cut4meRemix,
}, {
  title: 'Hallucinogen',
  tracklist: 'tracklist',
  image: hallucinogen,
}, {
  title: 'Hallucinogen - Remix',
  tracklist: 'tracklist',
  image: hallucinogenRemix,
}];

const playlists = [{
  title: 'Aquaphoria',
  tracklist: 'tracklist',
  image: aquaforia,
}];

const Music = ({ history, pageTransitionState }) => (
  <div styleName="wrap">
    <div styleName="background-music"></div>
    <Albums />
  </div>
);


export default Music;
