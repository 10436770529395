import React, { Component, Fragment } from 'react';

// import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

import NavLink from 'src/components/ui/NavLink';

// import PageTransitionState from 'src/store/PageTransitionState';

import TimelineLite from "gsap/TimelineLite";
import Easing from "gsap/umd/EasePack";
import styles from './Nav.module.scss';

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navOpen: false,
      navShowing: true,
      scrolled: true,

    }
    this.openTL = new TimelineLite({
      paused: true,
      defaultEase: Easing.Power3.easeInOut,
    });

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // this.setInitialNav();
      window.scrollTo(0, 0);
    }
    if (prevState.navOpen !== this.state.navOpen) {
      if (this.state.navOpen) {
        this.openTL.play();
      } else {
        this.openTL.reverse();
      }
    }
  }
  getClassName(navOption) {
    const {pathname} = this.props.location;
    if (pathname === `/${navOption}`) {
      return 'is-current-route';
    }
    return '';
  }


  render() {
    const { navShowing } = this.state;

    return (
      <Fragment>
        <div styleName={`wrap ${navShowing ? '' : 'hidden'}`} className="l">
            {/* <div styleName={`nav-link tl ${this.getClassName('music')}`}>
              <NavLink className={styles['link']} to="/music">MUSIC</NavLink>
            </div>
            <div styleName={`nav-link tr ${this.getClassName('shop')}`}>
              <NavLink className={styles['link']} to="/shop">SHOP</NavLink>
            </div> */}
            <div styleName={`nav-link bl ${this.getClassName('live')}`}>
              <NavLink className={styles['link']} to="/live">LIVE</NavLink>
            </div>
            <div styleName={`nav-link br ${this.getClassName('videos')}`}>
              <NavLink className={styles['link']} to="/videos">VIDEO</NavLink>
            </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(Nav);
