import ololog from 'ololog';
import bullet from 'string.bullet';
import { dim } from 'ansicolor';

const log = ololog.configure ({
  locate: false,
  time: {
    yes: true,
  }
})

export const createLog = (tag) => {
  return log.configure({
    tag: (lines) => {
      return bullet (dim (`[${tag}]`) + ': ', lines)
    }
  });
}
export default log;
