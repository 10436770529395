import React from 'react';
// import styled from 'styled-components';
// import Page from 'src/components/layout/Page';
// import ReactSVG from 'react-svg';
import ReactSVG from 'react-svg';
import twitter from 'src/assets/img/twitter.svg';
import soundcloud from 'src/assets/img/soundcloud.svg';
import instagram from 'src/assets/img/instagram.svg';
import facebook from 'src/assets/img/facebook.svg';

import  './home.module.scss';


const socialLinks = [
  {
    name: 'TWITTER',
    link: 'https://twitter.com/kelelam',
    element: (
      <ReactSVG src={twitter} />
    ),
  }, {
    name: 'SOUNCLOUD',
    link: 'https://soundcloud.com/kelelam',
    element: (
      <ReactSVG src={soundcloud} />
    ),
  }, {
    name: 'Instagram',
    link: 'https://www.instagram.com/kelelam/',
    element: (
      <ReactSVG src={instagram} />
    ),
  }, {
    name: 'Facebook',
    link: 'https://www.facebook.com/kelelamusic',
    element: (
      <ReactSVG src={facebook} />
    ),
  }
]

const Home = ({ history, pageTransitionState }) => (
  <div styleName="wrap">
    <div styleName="background"></div>
    <div styleName="social">
      {socialLinks.map((social) => (
        <a styleName="social-link" key={social.name} href={social.link} target="_blank">
          {social.element}
        </a>
      ))}
    </div>
  </div>
);


export default Home;
