import React, { Component } from 'react';

import ApiWrap from 'src/components/misc/ApiWrap';
import api from 'src/tools/api';
import aquaforia from 'src/assets/img/album/aquaforia.jpg';

import AlbumsView from 'src/components/misc/Albums/AlbumsView';


const playlists = [{
  title: 'Aquaphoria',
  tracklist: 'tracklist',
  image: aquaforia,
  link: 'https://soundcloud.com/kelelam/aquaphoria',
}];

class AlbumsContainer extends Component {
  render() {
    const apiUrl = 'https://kelela.warp.net/cart/embed/products?ids=20229633,20229634,20229635,20229636,20229637,20229638,20229639,20229640,20229641,20229642,20229643,20245387,20245388,20245389,20245390,20245391,20245392,20245393';
    return (
      <ApiWrap url={apiUrl}>{(response) => {
        const groups = {};
        response.forEach((item) => {
          const group = item.response.group;
          if (group.id in groups) {
            groups[group.id].items.push(item);
          } else {
            groups[group.id] = {
              items: [item],
              image: item.response.imageUrl,
              title: item.response.title,
            }
          }
        });
        console.log(groups);
        return (
          <AlbumsView albums={groups} playlists={playlists}/>

        )
      }}</ApiWrap>

    )
  }
}
export default AlbumsContainer;
