import React, { Component } from 'react';
import ShopifyState from 'src/store/ShopifyState';
import Spinner from 'react-spinkit';
import emitter from 'src/tools/emitter';

import './Product.module.scss';

const sizesMap = {
  'SMALL': 'S',
  'MEDIUM': 'M',
  'LARGE': 'L',
  'X-LARGE': 'XL',
  'XX-LARGE': 'XXL',
}

const displaySize = (size) => {
  return size in sizesMap ? sizesMap[size] : size;
}

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVariant: false,
      error: false,
      loading: false,
    }

    this.selectVariant = this.selectVariant.bind(this);
    this.addToCart = this.addToCart.bind(this);
  }
  selectVariant(variant) {
    this.setState({
      selectedVariant: variant,
    });
  }
  addToCart() {
    const {selectedVariant} = this.state;
    if (!selectedVariant) {
      this.setState({
        error: "Please Select Size Above",
      });
      return;
    }
    this.setState({
      loading:true,
    });

    ShopifyState.addVariantToCart(selectedVariant.id, 1).then(() => {
      emitter.emit('item-added', this.props.product);
      this.setState({
        selectedVariant: false,
        loading: false,
        error: false,
      });
    })
    .catch((error) => {
      this.setState({
        error,
      });
    })


  }
  render() {
    const { product } = this.props;
    const { selectedVariant, error, loading } = this.state;

    console.log(!!selectedVariant);
    return (
      <div key={product.id} styleName="product">
        <div styleName="product-image-wrap">
          <div styleName="product-image" style={{backgroundImage: `url(${product.images[0].src})`}}></div>
        </div>
        <div styleName="product-details" className="adf">
          <div style={{animationDelay: `100ms`}} styleName="product-title"> {product.title}</div>
          <div style={{animationDelay: `200ms`}} styleName="product-price">${product.variants[0].price}</div>
          <div style={{animationDelay: `300ms`}} styleName="product-sizes">
            {product.variants.map((variant) => (
              <button
                key={variant.id}
                styleName={`size-button ${selectedVariant.id === variant.id ? 'selected' : ''}`}
                onClick={() => this.selectVariant(variant)}
              >
                {displaySize(variant.title)}
              </button>
            ))}
          </div>
          <div style={{animationDelay: `400ms`}} styleName="product-button-wrap" className="1">
            <button disabled={loading} onClick={this.addToCart} styleName={`product-button ${loading? 'loading' :''} ${!!selectedVariant ? 'valid' : ''}`}>Add to Cart{' '}
              {selectedVariant && (
                <span>
                  ({displaySize(selectedVariant.title)})
                </span>
              )}
              {loading && (
                <Spinner name='double-bounce' />
              )}
            </button>
            {error && (
              <div styleName="product-warning">{error}</div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default Product;
