import React, { Component } from 'react';
import { withRouter } from 'react-router';

import './BottomCurtain.module.scss'

class BottomCurtain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animating: false,
    }
  }
  componentWillUpdate(nextProps) {
    if(this.props.location.pathname !== nextProps.location.pathname) {
      // console.log(this.props.location.pathname, nextProps.location.pathname);
      // if (nextProps.location.pathname === '/pre-checkout') {
      //   this.setState({
      //     animating: true,
      //   });
      // }
    }
    if (nextProps.leaving !== this.props.leaving) {
      // if (this.props.location.pathname === '/pre-checkout') {
        // if (this.props.leaving) {
        //   setTimeout(() => {
        //     this.setState({
        //       animating: false,
        //     });
        //   }, 400);
        // }
      // }
    }
  }
  render() {
    const { animating } = this.state;
    return (
      <div styleName={`bottom-curtain ${animating ? 'leaving' : ''}`} />
    );
  }
}

export default withRouter(BottomCurtain);
