const loadImage = (src) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      console.log( `loaded: ${src}`);
      resolve(src);
    };
    img.onerror = () => {
      console.error(`[prefetch-image]: "${src}" failed`);
      //still resolve even if some image failed loading
      resolve(src);
    };
    img.src = src;
  });
}

export default loadImage;
