import { Container } from 'unstated';

const noop = () => {};

class PageTransitionState extends Container {
  state = {
    leaving: false,
    loading: true,
    destination: '',
  };
  completeLoad() {
    this.setState({
      loading: false,
    });
  }

  leave(destination = '') {
    document.documentElement.classList.add('leaving');
    this.setState({
      leaving: true,
      destination,
    });
  }

  completeLeave(cb = noop) {
    document.documentElement.classList.remove('leaving');
    this.setState({
      leaving: false,
      destination: '',
    }, cb);
  }
}

const s = new PageTransitionState();

export default s;//PageTransitionState;
