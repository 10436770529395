import React from 'react';
import './Close.module.scss';

const Close = ({
  onClick
}) => (
  <button onClick={onClick}>
    <div styleName="outer">
      <div styleName="inner-1"/>
      <div styleName="inner-2"/>
    </div>
  </button>
);

export default Close;
