import axios from 'axios';
import lscache from 'lscache';

import { createLog } from 'src/tools/log';
import devlog from 'src/tools/devlog';

const log = createLog('Cachexios');


export const cache = {};
const cachexios = (opts) => {
  if (!('url' in opts)) {
    log.red('Url parameter needed.');
  }
  devlog(`[${opts.method}] ${opts.url}`);
  if (cache[opts.url]) {
    log.green('in-memory cache hit.');
    return Promise.resolve(cache[opts.url]);
  }
  // const browserCacheFetch = lscache.get(opts.url);
  // if (browserCacheFetch) {
  //   log.green('browser cache hit.');
  //   return Promise.resolve(browserCacheFetch);
  // }
  return axios(opts).then((result) => {
    cache[opts.url] = result;
    lscache.set(opts.url, result, 5)
    return result;
  })
};

export default cachexios;
