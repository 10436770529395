import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import Client from 'shopify-buy';
import localForage from 'localforage';
// import emitter from 'src/tools/emitter';

window.localForage = localForage;

const client = Client.buildClient({
  domain: 'kelela-store.myshopify.com',
  storefrontAccessToken: '722759bd3bb8d17022dbb2498fca4d97'
});

const rootEl = document.getElementById('𝔯𝔢𝔞𝔠𝔱-𝔯𝔬𝔬𝔱');

ReactDOM.render(<App client={client} />, rootEl);


if (module.hot) {
  module.hot.accept('./App', () => {
    const AppAgain = require('./App').default
    ReactDOM.render(
      <AppAgain client={client} />,
      rootEl
    )
  })
}
